import React from 'react'
import { graphql } from 'gatsby'

import BlogPostLayout from '../components/layouts/blog-post'

const BlogPostTemplate = ({ data: { post, latestPosts }, location }) => {
  const data = {
    post: post,
    latestPosts: latestPosts.nodes,
    location: location,
    meta: {
      title: post.title,
      description: post.description,
      imageUrl: post.keyVisual ? post.keyVisual.metaImage.src : null,
      slug: `blog/${post.slug}`,
      locale: post.node_locale,
      datePublished: post.date,
      dateModified: post.dateModified,
      author: {
        name: post.author.name
      }
    }
  }
  return <BlogPostLayout {...data} />
}

export const query = graphql`
  query PostQuery($id: String!, $locale: String!) {
    post: contentfulPageBlogPost(id: {eq: $id}) {
      id
      slug
      title
      node_locale
      description
      date: createdAt(formatString: "YYYY-MM-DD")
      date_de: createdAt(formatString: "DD. MMMM YYYY", locale: "de")
      date_en: createdAt(formatString: "MMMM DD, YYYY", locale: "en")
      dateModified: updatedAt
      author {
        name
        profilePicture {
          fluid(maxWidth: 72, maxHeight: 72, resizingBehavior:FILL, cropFocus: FACE, quality: 85) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
      keyVisual {
        fluid(resizingBehavior: FILL, cropFocus: CENTER, maxWidth: 2400, maxHeight: 1024, quality: 80) {
          ...GatsbyContentfulFluid_withWebp
        }
        metaImage: fixed(width: 1200, height: 630, quality: 90, resizingBehavior: FILL) {
          src
        }
      }
      blogBody {
        json
      }
    }
    latestPosts: allContentfulPageBlogPost(limit: 4, sort: {fields: createdAt, order: DESC}, filter: {node_locale: {eq: $locale}}) {
			nodes {
        ...BlogCard
      }
		}
  }
`

export default BlogPostTemplate