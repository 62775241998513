
import React from 'react'
import Img from 'gatsby-image'
import { BLOCKS } from '@contentful/rich-text-types'
import { useViewportScroll, useTransform, motion } from 'framer-motion'
import { FormattedMessage } from 'react-intl';

import Icon from '../icon'
import RichText from '../rich-text'
import Link from '../link'
import BlogTeaser from '../modules/blog-teaser'
import SEO from '../modules/seo/seo'

const BlogPostLayout = ({ post, latestPosts, location, meta }) => {

  const { scrollY } = useViewportScroll()
  const imgStyle = {
    y: useTransform(scrollY, [0, 84, 400], [0, 0, 100], { clamp: false }),
    scale: useTransform(scrollY, [0, 400], [1, 1.1], { clamp: false }),
  }

  const options = {
    renderNode: {
      [BLOCKS.HEADING_2]: (_node, children) => (
        <h2 className="display-4 mt-7 mb-5">
          {children}
        </h2>
      ),
      [BLOCKS.HEADING_3]: (_node, children) => (
        <h3 className="mt-5 mb-3">
          {children}
        </h3>
      ),
      [BLOCKS.HEADING_5]: (_node, children) => (
        <h5 className="font-size-lg mt-4 mb-3">
          {children}
        </h5>
      ),
      [BLOCKS.PARAGRAPH]: (_node, children) => (
        <p className="mb-4 text-body line-height-longtext">
          {children}
        </p>
      ),
    }
  }

  return (
    <div className="page-margin">
      <SEO {...meta} isBlogPost />

      <section
        style={{
          backgroundColor: "#F1F4F8",
          minHeight: 1,
          overflow: "hidden"
        }}
      >
        {post.keyVisual && post.keyVisual.fluid && (
          <motion.div style={imgStyle}>
            <Img
              fluid={post.keyVisual.fluid}
              style={{ width: "100%", height: "100%" }}
              imgStyle={{ objectFit: "cover" }}
            />
          </motion.div>
        )}
      </section>

      <section className="pt-8 pt-md-9">
        <ResponsiveContainer>
          <h1 className="display-4 text-center">
            {post.title}
          </h1>
          <p className="lead mb-7 text-center text-muted">
            {post.description}
          </p>
          <MetaInfo post={post} shareLink={location.href} />
        </ResponsiveContainer>
      </section>

      <section className="pt-6 pt-md-8">
        <ResponsiveContainer>
          <RichText content={post.blogBody} options={options} />
        </ResponsiveContainer>
      </section>

      <section className="pt-8 pt-md-9 pb-12">
        <ResponsiveContainer>
          <MetaInfo post={post} shareLink={location.href} />
        </ResponsiveContainer>
      </section>

      <BlogTeaser blogPosts={latestPosts} />
    </div>
  )
}

const ResponsiveContainer = ({ children }) => (
  <div className="container">
    <div className="row justify-content-center">
      <div className="col-12 col-md-10 col-lg-9 col-xl-8">
        {children}
      </div>
    </div>
  </div>
)

const MetaInfo = ({ post, shareLink, ...props }) => (
  <div className="row align-items-center py-5 border-top border-bottom" {...props}>
    <div className="col-auto">

      {/*--- Avatar ---*/}
      <div className="avatar avatar-lg">
        <Img fluid={post.author.profilePicture.fluid} alt={post.author.name} className="avatar-img rounded-circle" />
      </div>

    </div>
    <div className="col ml-n5">

      {/*--- Name ---*/}
      <h6 className="text-uppercase mb-0">
        {post.author.name}
      </h6>

      {/*--- Date ---*/}
      <time className="font-size-sm text-muted" dateTime={post.date}>
        <FormattedMessage id="publishedOn" /> {post[`date_${post.node_locale}`]}
      </time>

    </div>
    <div className="col-auto">

      {/*--- Share icons ---*/}
      <span className="h6 text-uppercase text-muted d-none d-md-inline mr-4">
        <FormattedMessage id="share" />:
      </span>
      <ul className="d-inline list-unstyled list-inline list-social">
        <li className="list-inline-item list-social-item mr-4">
          <Link to={`https://www.facebook.com/sharer/sharer.php?u=${encodeURI(shareLink)}&display=popup`} className="text-decoration-none">
            <Icon id="facebook" className="list-social-icon text-muted" />
          </Link>
        </li>
        <li className="list-inline-item list-social-item mr-0">
          <Link to={`https://twitter.com/intent/tweet?url=${encodeURI(shareLink)}`} className="text-decoration-none">
            <Icon id="twitter" className="list-social-icon text-muted" />
          </Link>
        </li>
      </ul>

    </div>
  </div>
)

export default BlogPostLayout